.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-container {
  display: flex;
  justify-content: space-between; 
  width: 100%;
  font-size: 14pt;
  color: #666666;
}

.p-text {
  line-height: 1.5;
  font-size: 18px;
}

.b-text{
  font-size: 20px;
}

.m-h1{
  font-size: 30px;
}

.content {
  padding: 40px; 
}

@media (max-width: 768px) { 
  /* 모바일 화면 */
  .content {
    padding: 20px; 
  }

  .text-container{
    gap: 15px; 
    font-size: 13px;
  }

  .m-h1{
    font-size: 24px;
  }
}
